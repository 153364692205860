* {
  box-sizing: border-box;
}

textarea:focus,
button:focus {
  outline: none;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: HelveticaNeue;
  src: local("Helvetica Neue Medium"), local("HelveticaNeue-Medium"),
    url(./assets/fonts/HelveticaNeue\ Medium.ttf);
}

html,
body,
#root {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #0021ac;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  overscroll-behavior: none;
  touch-action: none;
  -webkit-user-drag: none;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: HelveticaNeue -apple-system, helvetica neue, helvetica, arial,
    sans-serif;
  color: white;
  background-color: #0021ac;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  pointer-events: none;
  overflow: hidden;
  background-color: #0021ac;
  display: flex;
  align-items: center;
  justify-content: center;
}

#mainCanvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  pointer-events: none;
  overflow: hidden;
}

.galleryContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  overflow: hidden;
}

.galleryPhoto {
  position: absolute;
  object-fit: cover;
  object-position: center;
}

.topBottomContainer {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.topBottomContainer > div {
  flex: 1;
  width: 300px;
  max-height: 62%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 600px) {
  .topBottomContainer > div {
    width: 350px;
  }
}

.topBottomContainer p {
  color: white;
  text-transform: uppercase;
  text-align: center;
  text-align-last: center;
  margin: 5px;
  font-size: 1rem;
}

.shirtButtonsContainer {
  display: flex;
  flex-direction: column;
}

.zoomButtonsContainer {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
  padding-bottom: 3%;
}

.textButton {
  color: white;
  background-color: transparent;
  text-transform: uppercase;
  margin: 10px;
  padding: 10px;
  border-width: 0;
  text-align: center;
  align-items: center;
  transition: 0.3s;
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 250px;
  font-size: 0.8rem;
}

.borderedButton {
  border: solid 1px white;
}

.navArrowContainer {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
  left: 50%;
  transform: translate(-50%, 0);
}

.navArrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
}

.navArrow p {
  opacity: 0.5;
  margin: 5px;
  color: white;
  text-transform: uppercase;
  padding: 5px;
  font-size: 0.8rem;
}

.topArrow {
  top: 10px;
}

.bottomArrow {
  bottom: 10px;
}
